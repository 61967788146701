<template>
	<div>
		<el-row :gutter="20">
		
			<el-col :span="22">
				<div class="grid-content bg-purple-light">
					<div class="search">
						<el-form :inline="true"  ref="formInline" :model="formInline" class="demo-form-inline">

							<el-form-item label="税源名称" prop="service">
								<el-input v-model="formInline.service" placeholder="税源名称"></el-input>
							</el-form-item>
                            <el-form-item label="代理名称" prop="agent">
								<el-input v-model="formInline.agent" placeholder="代理名称"></el-input>
							</el-form-item>
							<el-form-item label="审核" prop="status">
								<el-select v-model="formInline.status" placeholder="审核">
								<el-option label="审核中" value="0"></el-option>
								<el-option label="已通过" value="1"></el-option>
								<el-option label="已驳回" value="2"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="getList()">查询</el-button>
								<el-button @click="reset('formInline')">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-table ref="listTable" v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column align="center" prop="id" label="申请编号" sortable width="120"></el-table-column>
            
			<el-table-column align="center" label="代理名称">
				<template slot-scope="scope" >
					{{scope.row.agent.name}}
				</template>
			</el-table-column>
			<el-table-column align="center"  label="代理联系电话">
				<template slot-scope="scope" >
					{{scope.row.agent.phone}}
				</template>
			</el-table-column>
            <el-table-column align="center" label="税源名称">
				<template slot-scope="scope" >
					{{scope.row.service.name}}
				</template>
			</el-table-column>
			<el-table-column align="center"  label="税源logo">
				<template slot-scope="scope" >
					<el-image style="width: 100px; height: 100px" :src="url+scope.row.service.logo" ></el-image>
				</template>
			</el-table-column>
		
			<el-table-column align="center" label="状态" >
				<template slot-scope="scope" >
					<el-tag v-if="scope.row.status==0" type="info">审核中</el-tag>
					<el-tag v-else-if="scope.row.status==1">已通过</el-tag>
					<el-tag v-else-if="scope.row.status==2" type="danger">已驳回</el-tag>
					<!-- <template v-if="scope.row.type==2"  >
						<TextOverflow >{{scope.row.result}} </TextOverflow>
					</template> -->
				</template>
			</el-table-column>
			<el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button  @click="details(scope.row.id)" type="text" size="small">详情</el-button>
					<!-- <el-button  @click="detail(scope.row.id,1)" type="text" size="small">通过</el-button> -->
					<!-- <el-button  @click="detail(scope.row.id,2)" type="text" size="small" style="color: #F56C6C;">驳回</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @prev-click="prev" @next-click="next" @current-change="CurrentChange" layout="total,prev, pager, next" :page-size="pageSize" :total="total"></el-pagination>
		<Modal v-model="router" width="40%"  @on-cancel="ModalCancel" :mask="false" title="合作信息">
			<router-view @routerCancel="ModalCancel"></router-view>
		</Modal>
	</div>
</template>

<script>
	import {request} from "@/library/request.js";
	// import create from "components/task/create.vue"
	// import edit from "components/task/edit.vue"
	// import TextOverflow from "components/common/TextOverflow.vue"
	export default {
		components:{
			// create,
			// edit,
			// TextOverflow
		},
		data(){
			return{
				data:[],
				total:0,
				pageSize:15,
				currentPage:0,
				url:this.common.url,
				loading: false,
				router:false,
				formInline:{
					name:"",
					service:"",
					status:"",
                    agent:""
				},
			}
		},
		methods: {
			getList(){
				this.loading = true
				this.$Loading.start();
				var search = {
					limit:this.pageSize,
					page:this.currentPage,
					service:this.formInline.service,
                    agent:this.formInline.agent,
					searchParams:{
						'name-%%':this.formInline.name,
						'status-=':this.formInline.status,
					},
					
				}
				return request({
					url:'cooperation/list',
					method:"get",
					params:search
				}).then(response  => {
					this.$Loading.finish();
					this.loading = false
					this.date = response.data.date
					this.data = response.data.data
					this.total = response.data.total
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			prev(value){
				this.currentPage = value
				this.getList()
			},
			next(value){
				this.currentPage = value
				this.getList()
			},
			CurrentChange(value){
				this.currentPage = value
				this.getList()
			},
			reset(formName){
				// console.log(this.$refs[formName])
				this.$refs[formName].resetFields();
				this.getList()
			},
			details(id){
				this.router=true
				this.$router.push({
					path:'/cooperation/detail/'+id
				})
			},
			 ModalCancel(res){
				this.router=false
				this.$router.push({
					path:'/cooperation/list'
				})
				if(res){
					this.getList()
				}
				
			},
		},
		mounted() {
			var route = this.$route.path.split('/');
			// console.log(route)
			if(route.length>3){
				this.ModalCancel()
			}
			this.getList()
		}
	}
</script>

<style>
	/* @import url('/css/app.css'); */
	/* .ivu-modal-body {
		height: 600px;
		overflow-y: scroll;
	} */
	.task-modal .ivu-modal-body{
		height: 600px;
		overflow-y: auto;
	}
</style>